export default {
  headers: [
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/rava/logo.png',
        url: 'https://joearizona.com/',
      },
      breakpoint: 1003,
      desktopMenu: {
        type: 'center', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: 'on-rava-header-logo',
        },
        headerClassName: 'on-rava-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-rava-mheader-logo',
        },
        type: 'mobile-center', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-rava-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-rava-mheader',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-rava-mheader-menu',
        itemClassName: 'on-rava-mheader-item',
        menuClassName: 'on-rava-mheader-folder',
        menuLabelActiveClassName: 'on-rava-mheader-active',
        menuItemClassName: 'on-rava-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'on-rava-header-buy-item',
            content: 'Buy',
            menuItems: [
              {
                id: 'on-rava-header-buy-item-buy',
                content: 'Find Your Dream Home',
                url: 'https://search.joearizona.com/',
              },
              {
                id: 'on-rava-header-buy-item-cash-offer',
                content: 'Cash Offer',
                url: 'https://cashoffer.joearizona.com',
              },
            ],
          },
          {
            id: 'on-rava-header-sell-item',
            content: 'Sell',
            menuItems: [
              {
                id: 'on-rava-header-sell-item-valuation',
                content: 'Home Valuation',
                url: 'https://joearizona.com/home-valuation',
              },
              {
                id: 'on-rava-header-sell-item-why-us',
                content: 'Why List With Us',
                url: 'https://joearizona.com/why-list-with-us',
              },
              {
                id: 'on-rava-header-sell-item-trade-in',
                content: 'Trade In',
                url: 'https://trade-in.joearizona.com',
              },
            ],
          },
          {
            id: 'on-rava-header-communities-item',
            content: 'Communities',
            menuItems: [
              {
                id: 'on-rava-header-communities-item-buckeye',
                content: 'Buckeye',
                url: 'https://joearizona.com/communities/buckeye-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-surprise',
                content: 'Surprise',
                url: 'https://joearizona.com/communities/surprise-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-avondale',
                content: 'Avondale',
                url: 'https://joearizona.com/communities/avondale-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-el-mirage',
                content: 'El Mirage',
                url: 'https://joearizona.com/communities/el-mirage-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-glendale',
                content: 'Glendale',
                url: 'https://joearizona.com/communities/glendale-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-goodyear',
                content: 'Goodyear',
                url: 'https://joearizona.com/communities/goodyear-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-laveen',
                content: 'Laveen',
                url: 'https://joearizona.com/communities/laveen-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-litchfield',
                content: 'Litchfield Park',
                url: 'https://joearizona.com/communities/litchfield-park-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-peoria',
                content: 'Peoria',
                url: 'https://joearizona.com/communities/peoria-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-phoenix',
                content: 'Phoenix',
                url: 'https://joearizona.com/communities/phoenix-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-more',
                content: 'More Communities',
                url: 'https://joearizona.com/communities',
              },
            ],
          },
          {
            id: 'on-rava-header-testimonials-item',
            content: 'Testimonials',
            url: 'https://joearizona.com/testimonials',
          },
          {
            id: 'on-rava-header-team-item',
            content: 'Team',
            url: 'https://joearizona.com/team-page',
          },
          {
            id: 'on-rava-header-contact-item',
            content: 'Contact Us',
            url: 'https://joearizona.com/new-contact-us',
          },
          {
            id: 'on-rava-header-blog-item',
            content: 'Blog',
            url: 'https://joearizona.com/blog',
          },
          {
            id: 'on-rava-header-home-item',
            content: 'Home',
            url: 'https://joearizona.com/',
          },
        ],
      },
    },
    {
      id: 'content-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/otok/logo.png',
        url: 'https://joearizona.com/',
      },
      breakpoint: 1003,
      desktopMenu: {
        type: 'center', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: 'on-rava-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-rava-header-item',
        menuClassName: 'on-rava-header-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-rava-header-folder-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'on-rava-header-buy-item',
            content: 'Buy',
            menuItems: [
              {
                id: 'on-rava-header-buy-item-buy',
                content: 'Find Your Dream Home',
                url: 'https://search.joearizona.com/',
              },
              {
                id: 'on-rava-header-buy-item-cash-offer',
                content: 'Cash Offer',
                url: 'https://cashoffer.joearizona.com',
              },
            ],
          },
          {
            id: 'on-rava-header-sell-item',
            content: 'Sell',
            menuItems: [
              {
                id: 'on-rava-header-sell-item-valuation',
                content: 'Home Valuation',
                url: 'https://joearizona.com/home-valuation',
              },
              {
                id: 'on-rava-header-sell-item-why-us',
                content: 'Why List With Us',
                url: 'https://joearizona.com/why-list-with-us',
              },
              {
                id: 'on-rava-header-sell-item-trade-in',
                content: 'Trade In',
                url: 'https://trade-in.joearizona.com',
              },
            ],
          },
          {
            id: 'on-rava-header-communities-item',
            content: 'Communities',
            menuItems: [
              {
                id: 'on-rava-header-communities-item-buckeye',
                content: 'Buckeye',
                url: 'https://joearizona.com/communities/buckeye-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-surprise',
                content: 'Surprise',
                url: 'https://joearizona.com/communities/surprise-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-avondale',
                content: 'Avondale',
                url: 'https://joearizona.com/communities/avondale-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-el-mirage',
                content: 'El Mirage',
                url: 'https://joearizona.com/communities/el-mirage-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-glendale',
                content: 'Glendale',
                url: 'https://joearizona.com/communities/glendale-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-goodyear',
                content: 'Goodyear',
                url: 'https://joearizona.com/communities/goodyear-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-laveen',
                content: 'Laveen',
                url: 'https://joearizona.com/communities/laveen-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-litchfield',
                content: 'Litchfield Park',
                url: 'https://joearizona.com/communities/litchfield-park-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-peoria',
                content: 'Peoria',
                url: 'https://joearizona.com/communities/peoria-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-phoenix',
                content: 'Phoenix',
                url: 'https://joearizona.com/communities/phoenix-real-estate',
              },
              {
                id: 'on-rava-header-communities-item-more',
                content: 'More Communities',
                url: 'https://joearizona.com/communities',
              },
            ],
          },
          {
            id: 'on-rava-header-testimonials-item',
            content: 'Testimonials',
            url: 'https://joearizona.com/testimonials',
          },
          {
            id: 'on-rava-header-team-item',
            content: 'Team',
            url: 'https://joearizona.com/team-page',
          },
          {
            id: 'on-rava-header-contact-item',
            content: 'Contact Us',
            url: 'https://joearizona.com/new-contact-us',
          },
          {
            id: 'on-rava-header-blog-item',
            content: 'Blog',
            url: 'https://joearizona.com/blog',
          },
          {
            id: 'on-rava-header-home-item',
            content: 'Home',
            url: 'https://joearizona.com/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-center', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: true,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
  ],
  footers: [
    {
      id: 'on-rava-footer-first',
      display: true,
      className: 'on-rava-footer-section on-rava-footer-section-first',
      sectionClassName: 'on-rava-footer-section-container',
      columns: [
        {
          id: 'col-one',
          className: 'on-rava-footer-column on-rava-footer-column-one ',
          style: {},
          items: [
            {
              id: 'col-one-img',
              type: 'image',
              className: 'on-rava-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/rava/logo-footer-exp.png',
              style: { width: '264px' },
            },
            {
              id: 'col-one-heading-one',
              type: 'heading',
              className: 'on-rava-footer-copyright-text pt-2',
              style: { fontWeight: 'bold' },
              data: '©eXp Realty 2022',
            },
            {
              id: 'col-one-heading-two',
              type: 'heading',
              className: 'on-rava-footer-copyright-text pt-0 pb-3',
              data: 'All Rights Reserved',
            },
            {
              id: 'col-one-socials',
              type: 'socialIcons',
              className: 'on-rava-footer-socials',
              itemClassName: 'on-rava-footer-socials-item',
              style: {},
              items: [
                {
                  id: 'col-one-linked',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                  url: 'https://www.linkedin.com/in/joebourland/',
                },
                {
                  id: 'col-one-insta',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                  url: 'https://www.instagram.com/joearizonahomes/',
                },
                {
                  id: 'col-one-facebook',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'https://www.facebook.com/joearizonahomes',
                },
              ],
            },
          ],
        },
        {
          id: 'col-two',
          className: 'on-rava-footer-column ',
          style: { textAlign: 'left' },
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: 'on-rava-footer-heading',
              style: {},
              data: 'Popular Pages',
            },
            {
              id: 'col-two-menu',
              type: 'menu',
              className: 'on-rava-footer-menu',
              itemClassName: 'mb-0 on-rava-footer-menu-popular',
              style: { display: 'grid' },
              items: [
                {
                  id: 'col-two-item-one',
                  data: 'Buy',
                  url: 'https://search.joearizona.com/',
                },
                {
                  id: 'col-two-item-two',
                  data: 'Sell',
                  url: 'https://joearizona.com/home-valuation',
                },
                {
                  id: 'col-two-item-three',
                  data: 'Communities',
                  url: 'https://joearizona.com/communities',
                },
                {
                  id: 'col-two-item-four',
                  data: 'Testimonials',
                  url: 'https://joearizona.com/testimonials',
                },
                {
                  id: 'col-two-item-five',
                  data: 'Team',
                  url: 'https://joearizona.com/team-page',
                },
                {
                  id: 'col-two-item-six',
                  data: 'Contact Us',
                  url: 'https://joearizona.com/new-contact-us',
                },

                {
                  id: 'col-two-item-eight',
                  data: 'Blog',
                  url: 'https://joearizona.com/blog',
                },
                {
                  id: 'col-two-item-nine',
                  data: 'Home',
                  url: 'https://joearizona.com/',
                  // style: { color: 'black' },
                },
              ],
            },
            {
              id: 'col-two-heading-two',
              type: 'heading',
              elementType: 'h1',
              className: 'on-rava-footer-heading pt-3 mt-3 pb-1',
              style: {},
              data: 'Office Hours',
            },
            {
              id: 'col-two-menu-two',
              type: 'menu',
              className: 'on-rava-footer-menu',
              itemClassName: 'on-rava-footer-menu-items mb-0',
              style: {},
              items: [
                {
                  id: 'col-two-item-twelve',
                  data: 'Monday - 8:00AM - 5:00PM',
                },
                {
                  id: 'col-two-item-thirteen',
                  data: 'Tuesday - 8:00AM - 5:00PM',
                },
                {
                  id: 'col-two-item-fourteen',
                  data: 'Wednesday - 8:00AM - 5:00PM',
                },
                {
                  id: 'col-two-item-fifteen',
                  data: 'Thursday - 8:00AM - 5:00PM',
                },
                {
                  id: 'col-two-item-sixteen',
                  data: 'Friday - 8:00AM - 5:00PM',
                },
                {
                  id: 'col-two-item-seventeen',
                  data: 'Saturday - Closed',
                },
                {
                  id: 'col-two-item-eighteen',
                  data: 'Sunday - Closed',
                },
              ],
            },
          ],
        },
        {
          id: 'col-three',
          className: 'on-rava-footer-info  ',
          style: {},
          items: [
            {
              id: 'col-three-menu',
              type: 'menu',
              className: 'pt-3 pt-xl-1',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-three-item-one',
                  data: 'Bourland Real Estate Team',
                  style: { lineHeight: '1.2' },
                  className: 'on-rava-footer-info-item',
                },
                {
                  id: 'col-three-item-two',
                  data: '16165 North 83rd Avenue #200',
                  className: 'on-rava-footer-info-item',
                },
                {
                  id: 'col-three-item-three',
                  data: 'Peoria, AZ, 85308',
                  className: 'on-rava-footer-info-item',
                },
              ],
            },
            {
              id: 'col-three-menu-two',
              type: 'itemContent',
              className: 'mb-0 on-rava-footer-contact',
              style: {},
              items: [
                {
                  id: 'col-three-item-four',
                  content: 'Phone: ',
                },
                {
                  id: 'col-three-item-five',
                  content: '(623) 322-8588',
                  url: 'tel:(623) 322-8588',
                },
              ],
            },
            {
              id: 'col-three-menu-three',
              type: 'itemContent',
              className: 'mb-0 on-rava-footer-contact',
              style: {},
              items: [
                {
                  id: 'col-three-item-six',
                  content: 'Email: ',
                },
                {
                  id: 'col-three-item-seven',
                  content: 'info@Joearizona.Com',
                  url: 'mailto:info@joearizona.com',
                },
              ],
            },
          ],
        },
        {
          id: 'col-four',
          className: 'on-rava-footer-column on-rava-footer-column-four ',
          style: {},
          items: [
            {
              id: 'col-four-img',
              type: 'image',
              className: 'on-rava-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/rava/logo-footer-exp.png',
              style: { width: '230px' },
            },
            {
              id: 'col-four-heading-one',
              type: 'heading',
              className: 'on-rava-footer-copyright-text pt-2',
              style: { fontWeight: 'bold' },
              data: '©eXp Realty 2022',
            },

            {
              id: 'col-four-heading-five',
              type: 'heading',
              className: 'on-rava-footer-copyright-text pt-2 pb-3',
              style: {},
              data: 'All Rights Reserved',
            },
            {
              id: 'col-four-socials',
              type: 'socialIcons',
              className: 'on-rava-footer-socials',
              itemClassName: 'on-rava-footer-socials-item',
              style: {},
              items: [
                {
                  id: 'col-four-linked',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                  url: 'https://www.linkedin.com/in/joebourland/',
                },
                {
                  id: 'col-four-insta',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                  url: 'https://www.instagram.com/joearizonahomes/',
                },
                {
                  id: 'col-four-facebook',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'https://www.facebook.com/joearizonahomes',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-rava-footer-second',
      display: true,
      className: 'on-rava-footer-section on-rava-footer-section-last',
      sectionClassName: 'on-rava-footer-section-container',
      columns: [
        {
          id: 'footer-disclaimer',
          className: 'align-center on-rava-footer-privacy-col',
          style: {},
          items: [
            {
              id: 'footer-privacy-policy',
              type: 'heading',
              elementType: 'a',
              url: 'https://joearizona.com/#',
              className: 'on-rava-footer-privacy-heading pt-5 pb-4',
              style: { fontSize: '13px' },
              data: 'PRIVACY POLICY',
            },
            {
              id: 'f-3-col-1-item-3',
              type: 'itemContent',
              className: 'pb-2 on-rava-footer-text',
              items: [
                {
                  id: 'f-3-col-1-item-4',
                  type: 'image',
                  className: 'on-rava-footer-text-logo',
                  style: { width: '80px' },
                  imageUrl: 'https://si-homelight.s3.amazonaws.com/sites/rava/logo-footer-armls.jpg',
                },
                {
                  content:
                    ' All information should be verified by the recipient and none is guaranteed as accurate by ARMLS. Copyright 2022 Arizona Regional Multiple Listing Service, Inc.',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'The Bourland Real Estate Team',
    agentDescription: 'Top agents in West Valley, Arizona',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/rava/agent.png',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/rava/hero-bg.jpg',
  },
};
